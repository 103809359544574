import React, { useContext } from 'react'
import CatalogServices from '../../services/general/catalogServices';
import { NavLink } from 'react-router-dom';
import img from "../../images/placeholder.png"
import { observer } from 'mobx-react-lite';
import { Context } from '../..';
import { useEffect } from 'react';

const CategoriesSlider2 = observer(({item}) => {
    const {app, productCategories} = useContext(Context);

    const fetachcategories = async () => {
        if(productCategories.categories.length < 1){
            const res = await CatalogServices.categories.index();

            if(res.statusCode === 200){
                productCategories.setCategories(res.content.items);
            }
        }
    }

    useEffect(() => {
        fetachcategories();
    }, [productCategories.categories])

  return (
    productCategories.categories.length >= 1 &&
	<section className="category-section-2">
		<div className="container-fluid-lg">
			<div className="title">
				<h2>{item?.title}</h2>
			</div>
            {
                <div className="row">
                    <div className="col-12">
                        <div className="category-grids">
                            {productCategories.categories.map((card, index)=>
                                <NavLink to={`/${app.lang}/catalog/${card.slug}`} key={index}>
                                    <div className="new-category-box">
                                        <img src={card.image} alt={card.title}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; 
                                                currentTarget.src = img
                                            }}
                                        />
                                        <h5>{card.title}</h5>
                                    </div>
                                </NavLink>
                            )}
                        </div>
                    </div>
                </div>
            }
		</div>
	</section>
  )
})

export default CategoriesSlider2